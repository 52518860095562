import React, { useMemo } from "react"
import PropTypes from "prop-types"
import "./BundleTile.less"
import EventureCard from "./EventureCard"
import { MoreInfoButton, PlayButton } from "./Buttons"
import { devLog, getImageUrl } from "../utils"
import eventureLogo from "../../static/assets/images/ico-card.svg"
import { PUBLISHED } from "../enums/RecordStates"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const BundleTile = ({
  data,
  onClick,
  type,
  onPlay,
  onShowInfo,
  onToggleFavorite,
  badge,
  progress,
  className,
  rank,
}) => {
  const {
    id,
    state,
    title,
    subtitle,
    short_description,
    description,
    criterion,
    price,
    landscape,
  } = data

  devLog({ data, title, subtitle, type })

  const handleClick = () => {
    onClick && onClick(data)
  }

  const coverUrl = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  const actions = useMemo(() => {
    let result = []

    if (state === PUBLISHED) {
      result.push(
        <PlayButton
          onClick={() => {
            onPlay(data)
          }}
        />
      )
    }

    return [
      ...result,
      <MoreInfoButton
        onClick={() => {
          onShowInfo(data)
          onClick && onClick(data)
        }}
      />,
    ]
  }, [data, state])

  const extra = useMemo(() => {
    const result = []

    return [...result]
  }, [criterion])

  return (
    <div className={`gift-card-tile ${className}`}>
      <EventureCard
        title={title}
        subtitle={subtitle}
        description={short_description}
        cover={coverUrl}
        badge={badge}
        rank={
          rank || (
            <div className="icon-wrapper">
              <img src={eventureLogo} alt="" />
            </div>
          )
        }
        progress={progress}
        actions={actions}
        extra={extra}
        type={type}
        onClick={handleClick}
      />
    </div>
  )
}

BundleTile.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onPlay: PropTypes.func,
  onShowInfo: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  badge: PropTypes.node,
  type: PropTypes.oneOf(["full", "compact"]),
  className: PropTypes.string,
}

BundleTile.defaultProps = {
  type: "full",
  onClick: () => {},
  onPlay: () => {},
  onShowInfo: () => {},
  onToggleFavorite: () => {},
  className: "",
}

export default BundleTile
